<template>
    <b-card title="Completed Packing List">
      <b-row>
        <!-- Modal for showing Packing Details-->
        <b-modal
          v-model="showJobModal"
          :title="detailModal.title"
          :id="detailModal.id"
          size="lg"
          ok-only
        >
          <b-row>
            <b-col cols="3">
              SO Number :
            </b-col>
            <b-col cols="9">
              {{ detailModal.content.orderNumber }}
            </b-col>
            <b-col cols="3">
              PickList Number :
            </b-col>
            <b-col cols="9">
              {{ detailModal.content.picklistNumber }}
            </b-col>
            <b-col cols="3">
              Created At:
            </b-col>
            <b-col cols="9">
              {{ detailModal.content.createdAt }}
            </b-col>
            <b-col cols="3">
              Updated At :
            </b-col>
            <b-col cols="9">
              {{ detailModal.content.updatedAt }}
            </b-col>
          </b-row>
          <template #modal-footer>
            <div class="w-100"></div>
          </template>
        </b-modal>
        <b-col cols="12">
          <b-row style="margin-bottom:15px;">
            <b-col cols="4">
              <b-form-select v-model="filterStatus" :options="statusOptions" placeholder="Filter by status"></b-form-select>
            </b-col>
            <b-col cols="4" />
            <b-col cols="4">
              <div>
                <b-row>
                  <span class="spin" v-if="showSpinner"></span>
                </b-row>
                <b-form-input
                  v-model="search"
                  @input="debounceSearch"
                  type="search"
                  placeholder="Type to Search"
                  style="float: right;"
                />
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-bottom:10px" class="show-on-mobile">
            <b-col>
              <b-form-checkbox
                v-model="stackedStatus"
                value="md"
                unchecked-value="false"
              >
                Stacked Table
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div style="overflow-x: visible;">
                <b-table
                  :filter="search"
                  hover
                  responsive
                  small
                  head-variant="dark"
                  outlined
                  :items="filteredItems"
                  :fields="headers"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :stacked="stackedStatus"
                >
                  <template #cell(jobOrderID)="row">
                    {{ row.item.id }}
                  </template>
                  <template #cell(orderNumber)="row">
                    {{ row.item.orderNumber }}
                  </template>
                  <template #cell(item)="row">
                    {{ simplyfiedItems(row.item) }}
                  </template>
                  <template v-slot:cell(actions)="row">
                    <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                      <template #button-content>
                        <feather-icon size="1x" icon="MenuIcon" />
                      </template>
                      <b-dropdown-item>
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="detail(row.item, row.index, $event.target)"
                          style="margin-right:10px"
                          ><feather-icon size="1x" icon="EyeIcon"
                        /></b-button>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  @input="queryPacking()"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                @change="onPageChange"
                :total-rows="totalRows"
                first-number
                last-number
                class="float-right"
                aria-controls="user-table"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    dateFormat,
    userAccess,
    userAccessManufacture,
    viewAccess,
  } from "@/utils/utils.js";
  
  import { mapActions, mapState } from "vuex";
  
  export default {
    data() {
      return {
        stackedStatus: "md",
        showSpinner: false,
        headers: [
          {
            key: "orderNumber",
            label: "SO Number",
            filterable: true,
            sortable: true,
          },
          { key: 'picklistNumber', label: 'Pick List No', filterable: true, sortable: true },
          { key: "lineItem", label: "Item", filterable: true, sortable: true },
          { key: "newStatus", label: "Status", filterable: true, sortable: true },
          { key: "pickListType", label: "Type", filterable: true, sortable: true },
          { key: "props.cable_length", label: "Cable Length", filterable: true, sortable: true},
          { key: "actions", label: "Actions" },
        ],
        //sort direction list
        directions: [
          { key: false, label: "Asc", sortable: true },
          { key: true, label: "Desc", sortable: true },
        ],
        workorderNumModal: false,
        workorderNumber: null,
        jobContext: null,
        search: null,
        disableStart: false,
        disableEnd: false,
        pageOptions: [5, 10, 20, 100],
        sortBy: "",
        sortDesc: false,
        buttonState: false,
        currentPage: 1,
        perPage: 5,
        totalRows: 1,
        promptOption: false,
        dataWarehouse: "",
        promptDetail: false,
        dataApproval: "",
        filterStatus: 'All',
        statusOptions: ['All', 'Ready to Pack', 'Start Packing', 'End Packing'],
  
        //for show jobdetails
        showJobModal: false,
        detailModal: {
          id: "detail-modal-bom",
          title: "",
          content: {
            name: "",
            type: "",
            createdAt: "",
            updatedAt: "",
          },
        },
      };
    },
    mounted() {
      // Saving Menu Setting on localstorage session so it still same even after reloading the page
      
      if (this.$session.has("perPageCompletedPackingManufacture")) {
        this.perPage = this.$session.get("perPageCompletedPackingManufacture");
      }
      if (this.$session.has("sortByCompletedPackingManufacture")) {
        this.sortBy = this.$session.get("sortByCompletedPackingManufacture");
      }
      if (this.$session.has("sortDescCompletedPackingManufacture")) {
        this.sortDesc = this.$session.get("sortDescCompletedPackingManufacture");
      }
      
      this.queryPacking();
      // if (this.$session.has("stackedStatusJoborderManufacture")) {
      // this.stackedStatus = this.$session.get("stackedStatusJoborderManufacture")
      // }
    },
  
    watch: {
      // Taking the Menu Setting from localstorage session so the setting will be the same as before
      perPage(perPageNew) {
        this.$session.set("perPageCompletedPackingManufacture", perPageNew);
      },
      sortBy(sortByNew) {
        this.$session.set("sortByCompletedPackingManufacture", sortByNew);
      },
      sortDesc(sortDescNew) {
        this.$session.set("sortDescCompletedPackingManufacture", sortDescNew);
      },
      // stackedStatus(stackedStatusNew) {
      // this.$session.set("stackedStatusJoborderManufacture", stackedStatusNew)
      // }
    },
  
    methods: {
      ...mapActions({
        getPicklist: "picklist/getCompletedPacking",
      }),

      processMetadata(metadata) {
        let { totalData } = metadata;
        this.totalRows = totalData;
      },

      queryPacking(){
        this.getPicklist({
          entry : this.perPage,
          page : this.currentPage,
        }).then((data) => {
          // console.log("data", data)
          this.processMetadata(data.metadata)
        }).catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
      },

      onPageChange(page) {
        this.currentPage = page;
        this.queryPacking();
      },

      detail(item, index, event) {
        this.$store
          .dispatch("picklist/getPicklistId", { id: item.id })
          .then((res) => {
            var data = this.$store.getters["picklist/getPicklistId"]
  
            var newCreated = dateFormat(data.createdAt).split(",")
            var newUpdated = dateFormat(data.updatedAt).split(",")
  
            this.detailModal.title = "Packing Details";
            (this.detailModal.id = data.id),
              (this.detailModal.content = {
                orderNumber: data.orderNumber,
                picklistNumber: data.picklistNo + "_" + data.picklistLine,
                items:{
                    name: data.lineItem,
                    orderNumber: data.orderNumber,
                    quantity: data.quantity,
                  },
                createdAt: newCreated[0] + newCreated[1].replace(/\./g, ':'),
                updatedAt: newUpdated[0] + newUpdated[1].replace(/\./g, ':'),
              });
          });
            this.showJobModal = true;
      },
      // add spinner while loading on searching data process
      debounceSearch(event) {
        this.showSpinner = true;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.showSpinner = false;
          this.search = event.target.value;
        }, 600);
      },
  
      simplyfiedItems(items) {
        return items.reduce((prev, next) => {
          if (next === null || next === undefined) {
            return prev;
          }
  
          let humanName = next.detail || next.name;
  
          return [...prev, humanName];
        }, []);
      },
  
    },
    computed: {
      pickLists() {
        var data = this.$store.getters["picklist/getCompletedPacking"].map((el) => {
          console.log(el);
          var newCreated = dateFormat(el.createdAt).split(",")
          var newUpdated = dateFormat(el.updatedAt).split(",")
          var statusText = ""
          if(el.props.statusNo == 55){
            statusText = "55 - end packing"
          }
          return{
            ...el,
            picklistNumber: el.picklistNo + "_" + el.picklistLine,
            newStatus : statusText,
            createdAt: newCreated[0] + newCreated[1],
            updatedAt: newUpdated[0] + newUpdated[1],
          }
          });
        return data
      },
      filteredItems() {
        let filteredByStatus = this.pickLists;

        if (this.filterStatus !== 'All') {
          filteredByStatus = filteredByStatus.filter(item => {
            if (this.filterStatus === 'Ready to Pack') {
              return (item.props.statusNo === 40 && item.pickListType === 'C') || (item.props.statusNo === 35 && item.pickListType === 'C');
            } else if (this.filterStatus === 'Start Packing') {
              return item.props.statusNo === 50;
            } else if (this.filterStatus === 'End Packing') {
              return item.props.statusNo === 55;
            }
            return true;
          });
        }

        if (this.search && this.search.trim() !== '') {
          const searchTerm = this.search.trim().toLowerCase();
          return filteredByStatus.filter(item => item.picklistNumber.toLowerCase().includes(searchTerm));
        } else {
          return filteredByStatus;
        }
      },
  
      permission() {
        return userAccess("completed packing");
      },
      rows() {
        return this.filteredItems.length;
      },
      // Create an options list from our fields
      sortOptions() {
        return this.headers
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      // Create an direction list that can be saved in session
      directionOptions() {
        return this.directions
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
    },
  };
  </script>
  
  <style>
  @media (min-width: 761px) {
    .show-on-mobile {
      display: none !important;
    }
  }
  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  .spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #0077b3;
    border-radius: 50%;
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    margin-top: 20px;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }
  </style>
  